import React, { memo, useContext, useEffect, useRef } from 'react';
import { VectorMap } from 'react-jvectormap';
import './world-map.css';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import SkeletonComponent from './components/Skeleton';
import { UserContext } from '../../Context/UserContext';

const WorldMapComponent = ({ data, detailsData, colorData, map_score_ary, isLoading }) => {
  const { is_paid_user } = useContext(UserContext);
  const navigate = useNavigate();
  const isMaxWidth = useMediaQuery({ maxWidth: 1536 });
  const mapRef = useRef(null);

  const handleClick = (e, countryCode) => {
    if (typeof data[countryCode] !== 'undefined') {
      navigate(`/user/dashboard/chart/${detailsData[countryCode].country_id}`);
    }
  };

  useEffect(() => {
    return () => {
      if (mapRef.current) {
        mapRef.current.tip.remove();
      }
    };
  }, [mapRef]);

  if (isLoading === true || Object.keys(data).length === 0) {
    return <SkeletonComponent />;
  }

  return (
    <React.Fragment>
      <VectorMap
        map={'world_mill'}
        ref={(map) => {
          if (mapRef.current) {
            mapRef.current.tip.remove();
          }
          if (map) {
            mapRef.current = map.$mapObject;
          }
        }}
        backgroundColor='transparent' //change it to ocean blue: #0077be
        // zoomOnScroll={true}
        zoomOnScroll={false}
        containerStyle={{
          height: isMaxWidth ? 'calc(100vh - 320px)' : 'calc(100vh - 345px)',
          width: '100%',
          // height: isMaxWidth ? 'calc(100vh - 294px)' : 'calc(100vh - 345px)',
          padding: '0px 0px 0px 0px',
        }}
        onRegionClick={handleClick} //gets the country code
        regionStyle={{
          initial: {
            fill: '#f0f8eb',
          },
          hover: {
            'fill-opacity': 1,
            cursor: 'pointer',
          },
        }}
        regionsSelectable={false}
        series={{
          regions: [
            {
              values: data, //this is your data
              scale: colorData, // your color game's here
              // scale: { '657168591cb257471c4ba554': '#2c1160', '657168591cb257471c4ba528': '#fcfcbe' }, // your color game's here
              normalizeFunction: 'polynomial',
              // min: 0,
              // max: 600,
            },
          ],
        }}
        zoomButtons={false}
        onRegionTipShow={(e, el, code) => {
          if (typeof data[code] !== 'undefined') {
            let upDownImage = '';
            if (detailsData[code].change > 0) {
              upDownImage = '<img src="/images/tabler_arrow-up.svg" width="100%" height="100%" />';
            } else if (detailsData[code].change < 0) {
              upDownImage = '<img src="/images/tabler_arrow-down.svg" width="100%" height="100%" />';
            }
            const customHtml = `<div style="width: 320px; height: 130px; background: #1B243A; border-radius: 10px;margin: 0px;border: 0.50px white solid;padding: 10px;overflow:hidden;">
                <div style="flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 12px; display: flex">
                  <div style="align-self: stretch; justify-content: flex-start; align-items: center; gap: 12px; display: inline-flex">
                    <div style="width: 32px; height: 32px; position: relative;margin: auto;border-radius: 50%;">
                      <img src="/images/country/${code.toLowerCase()}.svg" width="100%" height="100%" />
                    </div>
                    <div style="flex: 1 1 0; color: white; font-size: 26px; font-family: Poppins; font-weight: 600;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">
                      ${el.html()}
                    </div>
                  </div>
                <div style="flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 8px; display: flex">
                  <div style="width: 290px; justify-content: flex-start; align-items: flex-start; gap: 5px; display: inline-flex">
                    <div style="flex: 1 1 0; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 5px; display: inline-flex">
                      <div style="align-self: stretch; justify-content: flex-start; align-items: flex-start; gap: 10px; display: inline-flex">
                        <div style="flex: 1 1 0; text-align: center; color: white; font-size: 17px; font-family: Poppins; font-weight: 600; word-wrap: break-word">
                          Rank
                        </div>
                      </div>
                      <div style="align-self: stretch; justify-content: flex-start; align-items: flex-start; gap: 10px; display: inline-flex">
                        <div style="flex: 1 1 0; text-align: center; color: white; font-size: 17px; font-family:  Poppins; font-weight: 400; word-wrap: break-word">
                        ${detailsData[code].rank}
                        </div>
                      </div>
                    </div>
                  <div style="flex: 1 1 0; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 5px; display: inline-flex">
                    <div style="align-self: stretch; justify-content: flex-start; align-items: flex-start; gap: 10px; display: inline-flex">
                      <div style="flex: 1 1 0; text-align: center; color: white; font-size: 17px; font-family: Poppins; font-weight: 600; word-wrap: break-word">Score</div></div>
                        <div style="align-self: stretch; justify-content: flex-start; align-items: flex-start; gap: 10px; display: inline-flex">
                          ${
                            is_paid_user === true
                              ? `<div style="flex: 1 1 0; text-align: center; color: white; font-size: 17px; font-family: Poppins; font-weight: 400; word-wrap: break-word">
                          ${map_score_ary[code]}
                          </div>`
                              : `<div style="flex: 1 1 0; text-align: center; color: white; font-size: 17px; font-family: Poppins; font-weight: 400; word-wrap: break-word;filter: blur(8px); filter: 'blur(8px)';">
                          0000
                          </div>`
                          }
                        </div>
                      </div>
                    <div style="flex: 1 1 0; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 5px; display: inline-flex">
                      <div style="align-self: stretch; justify-content: flex-start; align-items: flex-start; gap: 10px; display: inline-flex">
                        <div style="flex: 1 1 0; text-align: center; color: white; font-size: 17px; font-family: Poppins; font-weight: 600; word-wrap: break-word">Change</div></div>
                          <div style="align-self: stretch; justify-content: center; align-items: center; gap: 5px; display: inline-flex">
                            ${
                              is_paid_user === true
                                ? `<div style='text-align: right; color: white; font-size: 17px; font-family: Poppins; font-weight: 400; word-wrap: break-word;'>
                                  ${Math.abs(detailsData[code].change)}
                                </div>
                                <div>${upDownImage}</div>`
                                : `<div style="text-align: right; color: white; font-size: 17px; font-family: Poppins; font-weight: 400; word-wrap: break-word ;filter: blur(8px); filter: 'blur(8px)';">
                                  0000
                                </div>`
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>`;
            el.html(customHtml);
          } else {
            el.html('');
          }
        }}
      />
    </React.Fragment>
  );
};

export default memo(WorldMapComponent);
