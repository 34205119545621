import React, { useContext } from 'react';
import { ToastComponent } from '../../Components';
import { Col, Modal, Row } from 'react-bootstrap';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import SelectBox from '../../Components/SelectBox';
import { bookDemoValidation, bookDemoIntialValues } from './constant';
import { AppContext } from '../../Hooks/useContextHook';
import DateTimePicker from '../../Components/dateTimePicker';
import './style.css';
import { useTimezoneSelect, allTimezones } from 'react-timezone-select';
import { useMutateBookDemoHook } from './hook/useApiHook';
import { AiOutlineClose } from 'react-icons/ai';

const labelStyle = 'original';
const timezones = {
  ...allTimezones,
  'Europe/Berlin': 'Frankfurt',
};

const BookDemo = () => {
  const postBookDemo = useMutateBookDemoHook();
  const { options } = useTimezoneSelect({ labelStyle, timezones });
  const { bookDemoOpen, setBookDemoOpen } = useContext(AppContext);

  const handleBookDemo = () => setBookDemoOpen(!bookDemoOpen);

  const handleSubmitBookDemo = (values) => {
    const reqData = {
      timezone: values?.time_zone?.value,
      timezone_label: values?.time_zone?.label,
      schedule_time: values?.schedule_time.replace(/['"]+/g, '') || '',
      schedule_time_alternate: values?.schedule_time.replace(/['"]+/g, '') || '',
      preferred_meet_service: values?.preferred_meet_service,
      explore_feature: values?.explore_feature,
    };

    postBookDemo.mutate(reqData, {
      onSuccess: (response) => {
        ToastComponent({
          type: 'success',
          message: response?.message || 'Demo Booked successfully',
        });
        handleBookDemo();
      },
      onError: (error) => {
        ToastComponent({
          type: 'error',
          message: error?.response?.data?.message || 'Server Error!',
        });
      },
    });
  };

  return (
    <div className='modal-container'>
      <Modal
        show={bookDemoOpen}
        backdrop={'static'}
        backdropClassName='contact-us-model-backdrop'
        onHide={handleBookDemo}
        keyboard={true}
        centered
        size='lg'
        className='contact-us-model'
      >
        <Modal.Header className='model-header'>
          <Modal.Title>
            <span className='d-block model-main-title contactus-title'>Book a Demo</span>
          </Modal.Title>
          <span className='model-header-action-wrap' onClick={handleBookDemo}>
            <AiOutlineClose className='model-header-action-icon' />
          </span>
        </Modal.Header>
        <div className='px-3 py-2 modal-body-content'>
          <Formik
            initialValues={bookDemoIntialValues}
            validationSchema={bookDemoValidation}
            onSubmit={handleSubmitBookDemo}
          >
            {({ handleSubmit, setFieldValue, isSubmitting, values }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div className='input-label-div'>
                    <label className='input-label-div-label'>Choose Time Zone</label>
                    <div className='input-label-div-input-div'>
                      <Field component={SelectBox} placeholder='Choose' name='time_zone' options={options} />
                    </div>
                    <ErrorMessage name='time_zone' component='div' className='input-label-div-error-msg' />
                  </div>
                  <div className='input-label-div  mt-2 mb-1'>
                    <Row className='dashboard-page-container-row'>
                      <Col md='6' xs='6' sm='6' lg='6' xl='6' xxl='6' className='mb-1'>
                        <label className='input-label-div-label'>Choose Date and Time</label>
                        <Field component={DateTimePicker} placeholder='Select Date/Time' name='schedule_time' />
                      </Col>
                      <Col md='6' xs='6' sm='6' lg='6' xl='6' xxl='6'>
                        <label className='input-label-div-label'>Choose Date and Time (Alternate)</label>
                        <Field
                          component={DateTimePicker}
                          placeholder='Select Date/Time'
                          name='schedule_time_alternate'
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className='input-label-div'>
                    <label className='input-label-div-label'>Which Video Call Platform Do You Prefer?</label>
                    <div className='input-label-div-input-div'>
                      <div className='types-meeting-btns'>
                        <button
                          className={`
                          ${
                            values.preferred_meet_service === 'Hangouts' ? 'meet-options-selected-btn' : ''
                          } meet-options-btn-Hangouts`}
                          type='button'
                          name='preferred_meet_service'
                          onClick={() => {
                            setFieldValue('preferred_meet_service', 'Hangouts');
                          }}
                        >
                          <img
                            src='/images/Hangouts_icon.png'
                            alt='zoom.png'
                            className='meet-options-btn-Hangouts-icon'
                          />
                        </button>
                        <button
                          className={`
                          ${
                            values.preferred_meet_service === 'Microsoft' ? 'meet-options-selected-btn' : ''
                          } meet-options-btn`}
                          type='button'
                          name='preferred_meet_service'
                          onClick={() => {
                            setFieldValue('preferred_meet_service', 'Microsoft');
                          }}
                        >
                          <img src='/images/microsoft.png' alt='user.png' />
                        </button>
                        <button
                          className={`
                          ${
                            values.preferred_meet_service === 'Zoom' ? 'meet-options-selected-btn' : ''
                          } meet-options-btn-zoom`}
                          type='button'
                          name='preferred_meet_service'
                          onClick={() => {
                            setFieldValue('preferred_meet_service', 'Zoom');
                          }}
                        >
                          <img src='/images/zoom.png' alt='zoom.png' className='meet-options-btn-zoom-icon' />
                        </button>
                      </div>
                    </div>
                    <ErrorMessage name='preferred_meet_service' component='div' className='input-label-div-error-msg' />
                  </div>
                  <div className='input-label-div mt-2'>
                    <label className='input-label-div-label'>
                      Tell Us More about what features you would like to explore in particular.
                    </label>
                    <div className='input-label-div-input-div'>
                      <textarea
                        className='dashboard-page-main-container-right-side-contact-form-textarea'
                        type='text'
                        onChange={(e) => setFieldValue('explore_feature', e.target.value)}
                        name='explore_feature'
                        placeholder='Write here...'
                      />
                    </div>
                    <ErrorMessage name='explore_feature' component='div' className='input-label-div-error-msg' />
                  </div>
                  <div className='contact-form-btn-box mt-1'>
                    <button type='submit' className='contact-form-submit-btn' disabled={isSubmitting}>
                      {isSubmitting ? 'Loading...' : 'Submit'}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal>
    </div>
  );
};

export default BookDemo;
